<!-- =========================================================================================
  File Name: .vue
  Description: eCommerce Item Detail page
========================================================================================== -->

<template>
  <div id="item-detail-page">
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
              <b></b><h1 class="mb-1">{{total}} Results for " Child Care " in ABC, France</h1>
   
          </div>
        </vx-card>
      </div>
    </div>
    <br />
    <br />
    <!--div class="knowledge-base-jumbotron">
      <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
        <h1 class="mb-1 text-white">Book an appointment</h1>
        <p
          class="text-white"
        >Our general dental services include diagnosis and treatment of conditions affecting teeth and gums. Our goal is always proactive, preventative care for optimum oral health.</p>
        <vs-input
          icon-no-border
          placeholder="Search Treatment or condition"
          v-model="treatmentSearchQuery"
          icon-pack="feather"
          icon="icon-search"
          size="large"
          class="w-full mt-6"
        />
      </div>
    </div -->




    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/1 sm:w-1/1 mb-base"
        v-for="item in organizations"
        :key="item.id" @click="$router.push(item.url).catch(() => {})"      
      > 
orgName
      specialities
      url
        <vx-card class="cursor-pointer">
          <img :src="item.attr01" height="120" class="mx-auto mb-4" />
          <h5 class="mb-2"><u><a :href="item.orgName" target="_blank">{{ item.orgName}}</a></u><feather-icon class="mb-2" icon="ChevronRightIcon" svgClasses="w-4 h-4"/></h5>
          <p class="mb-2">{{ item.specialities }}</p>
          <p class="mb-2"><b>Services: </b><vs-button size="medium" align="left" to="/">Schedule Now</vs-button></p>
          <p class="sm:mx-0 mx-4 mb-6">paraphonic unassessable foramination Caulopteris worral Spirophyton encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area bertram plotting unstarting quarterstaff.</p>
            
          <!--small v-for="ptype in item.patientType" :key="ptype.value">{{ ptype }}</small -->
        </vx-card>
        </div>
     
    </div>


  </div>
</template>
<script>
//import axios from '@/axios.js';

import Prism from "vue-prism-component";

export default {
  components: {
    Prism
  },
  data() {
    return {
      treatmentSearchQuery: "",
      organizations: [],
      total: 0,
      //Broken Tooth,Emergency, Extraction,Implant Consultation, Teeth Dentistry
      bannerImg:
        "https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png",
      error_occured: false,
      error_msg: ""
    };
  },
  computed: {
    documentCenterData() {
      //console.log(this.$store.state.careplan.maternitySiteOptions);
      return null;
      //return this.$store.state.careplan.maternitySiteOptions;
    },
    filteredKB() {
      //return this.$store.state.eCommerce.wishList.slice().reverse()
      //return this.appointmentTypeOptions.filter((item) => item.label.toLowerCase().includes(this.treatmentSearchQuery.toLowerCase()));
      //return this.$store.state.careplan.maternityAppointmentTypeOptions.filter(item =>
        //item.label
        //  .toLowerCase()
         // .includes(this.treatmentSearchQuery.toLowerCase())
      //);
      return null;
    }
  },
  methods: {
   
  },

  created() {

    this.$http.get('/organization/getAll')
      .then((response) => {    
        console.log(response) ; 

        var renderedOrgList = [];
        for (var k = 0; k < response.data.organizations.length; k++) {
        var org = {id: 0, orgName: '',specialities: '', url:''};

        org.id = response.data.organizations[k].id;
        org.orgName = response.data.organizations[k].orgName;
        org.specialities = response.data.organizations[k].specialities;
        org.url = '/apps/preventiveHealth/appointmentSelection/' +  response.data.organizations[k].id;
        
        renderedOrgList.push(org);

        }

        //this.organizations = response.data.organizations; 
        this.organizations = renderedOrgList; 
        console.log(renderedOrgList) ; 
        this.organizations.total 
        })
      .catch((error)   => { console.log(error) })

    //*his.$http.get('/organization/filterResourcePersonnel?ageGroupsServed=any&department=any&gender=any&groupType=5&insuranceAccepted=any&language=any&level=any&organizationId=-1&type=any&typeOfEmployment=any')
     // .then((response) => { console.log(response.data) })
    //  .catch((error)   => { console.log(error) }) 

  }
};
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
  background-image: url("https://healthminder-dental-website.s3.ap-southeast-1.amazonaws.com/Background%20%26%20Images/HMDentalCare_TreatmentsTopImg.png");
  background-size: cover;
}
</style>